<template>
  <v-container>
    <ValidationObserver ref="objects" v-slot="{ invalid, dirty }">
      <v-form>
        <v-row>
          <v-col cols="12" md="4">
            <ValidationProvider name="Type" rules="required">
              <v-select
                v-model="staffMemberLocal.staff_types_id"
                :disabled="loading"
                :items="lists.types"
                :menu-props="{ maxHeight: '400' }"
                item-text="title"
                item-value="id"
                :error-messages="errors"
                slot-scope="{ errors }"
                label="Type"
                clearable
              ></v-select>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="4">
            <ValidationProvider name="Status" rules="required">
              <v-select
                v-model="staffMemberLocal.staff_statuses_id"
                :disabled="loading"
                :items="lists.status"
                :menu-props="{ maxHeight: '400' }"
                item-text="title"
                item-value="id"
                :error-messages="errors"
                slot-scope="{ errors }"
                label="Status"
                clearable
              ></v-select>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="4">
            <ValidationProvider name="Branch">
              <v-select
                v-model="staffMemberLocal.branch_id"
                :disabled="loading"
                :items="lists.branches"
                :menu-props="{ maxHeight: '400' }"
                item-text="title"
                item-value="id"
                label="Branch"
                clearable
                :error-messages="errors"
                slot-scope="{ errors }"
              ></v-select>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="4">
            <ValidationProvider name="First Name" rules="required|min:2|max:60">
              <v-text-field
                v-model="staffMemberLocal.first_name"
                :disabled="loading"
                label="First Name"
                :error-messages="errors"
                slot-scope="{ errors }"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="4">
            <ValidationProvider name="Surname" rules="required|min:2|max:60">
              <v-text-field
                v-model="staffMemberLocal.surname"
                :disabled="loading"
                label="Surname"
                :error-messages="errors"
                slot-scope="{ errors }"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="4">
            <ValidationProvider name="Gender" rules="required">
              <v-select
                v-model="staffMemberLocal.genders_id"
                :disabled="loading"
                :items="lists.genders"
                :menu-props="{ maxHeight: '400' }"
                item-text="title"
                item-value="id"
                :error-messages="errors"
                slot-scope="{ errors }"
                label="Gender"
                clearable
              ></v-select>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="4">
            <ValidationProvider name="Email">
              <v-text-field v-model="staffMemberLocal.email" :disabled="loading" label="Email"></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="4">
            <ValidationProvider name="Phone">
              <v-text-field v-model="staffMemberLocal.phone" :disabled="loading" label="Phone"></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="4">
            <ValidationProvider name="Mobile">
              <v-text-field v-model="staffMemberLocal.mobile" :disabled="loading" label="Mobile"></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="4">
            <ValidationProvider name="Card #" rules="min:2">
              <v-text-field
                v-model="staffMemberLocal.card_number"
                :disabled="loading"
                label="Card #"
                :error-messages="errors"
                slot-scope="{ errors }"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="4">
            <ValidationProvider name="Card Expire" rules="date">
              <DateInput
                v-model="staffMemberLocal.card_expire"
                label="Card Expire"
                :disabled="loading"
                :error-messages="errors"
                slot-scope="{ errors }"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="4">
            <ValidationProvider name="Roles">
              <v-select
                v-model="staffMemberLocal.roles"
                :disabled="loading"
                :items="lists.roles"
                :menu-props="{ maxHeight: '400' }"
                item-text="title"
                item-value="id"
                :error-messages="errors"
                slot-scope="{ errors }"
                label="Roles"
                multiple
                clearable
              ></v-select>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider name="Street Address">
              <AddressField label="Street Address" v-model="staffMemberLocal.street_address" :disabled="loading" />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider name="Postal Address">
              <AddressField label="Postal Address" v-model="staffMemberLocal.postal_address" :disabled="loading" />
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider name="Postal Address same">
              <v-switch
                v-model="staffMemberLocal.same_address"
                :disabled="loading"
                label="Postal Address same as Street Address"
                :error-messages="errors"
                slot-scope="{ errors }"
              ></v-switch>
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider name="Notes">
              <v-textarea
                v-model="staffMemberLocal.notes"
                label="Notes"
                rows="3"
                :error-messages="errors"
                slot-scope="{ errors }"
                filled
                auto-grow
              ></v-textarea>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-btn @click="saveAction" :disabled="loading || invalid || !dirty" class="primary">Save</v-btn>
      </v-form>
    </ValidationObserver>
  </v-container>
</template>

<script>
import loadDash from 'lodash';
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, min, max } from 'vee-validate/dist/rules';
import { date } from '@/utils/helpers';
import { mapGetters } from 'vuex';
import store from '@/store/index';
import DateInput from '@/views/DateInput';
import AddressField from '@/views/AddressField';

extend('required', required);
extend('min', min);
extend('max', max);
extend('date', date);

export default {
  name: 'StaffMemberDetailForm',
  components: {
    ValidationProvider,
    ValidationObserver,
    AddressField,
    DateInput
  },
  computed: {
    /**
     * The mapGetters helper simply maps store getters to local computed properties:
     */
    ...mapGetters('staffMember', ['loading', 'staffMember', 'error', 'success', 'meta']),
    /*
     * We retrive the meta data for each requests. This allows us to update the lists
     * if they have changed in the application.
     */
    lists: function () {
      if (this.meta && this.meta.lists) {
        return this.meta.lists;
      }
      return {
        status: [],
        types: [],
        genders: [],
        branches: [],
        roles: []
      };
    },
    /**
     * To work with v-model and vuex, we only want to keep
     * a local copy until the user is ready to save.
     */
    staffMemberLocal: function () {
      const staff = loadDash.cloneDeep(this.staffMember);
      if (this.isNewRecord()) {
        staff.staff_types_id = 1;
        staff.staff_statuses_id = 1;
      }
      return staff;
    }
  },
  methods: {
    /**
     * Save method for the form
     */
    async saveAction() {
      const result = await this.$refs.objects.validate();
      if (result) {
        this.staffMemberLocal.organisation_id = this.organisation_id;
        this.staffMemberLocal.dob = this.dob;

        // TODO: Investigate why these are null
        if (this.staffMemberLocal.street_address == null) {
          this.staffMemberLocal.street_address = { street: '', suburb: null };
        }
        if (this.staffMemberLocal.postal_address == null) {
          this.staffMemberLocal.postal_address = { street: '', suburb: null };
        }

        await store.dispatch('staffMember/save', {
          id: this.staffMemberLocal.id,
          staffMember: this.staffMemberLocal
        });
      }
      this.resetValidation();
    },
    /**
     * Delete action for the form
     */
    deleteAction() {
      store.dispatch('staffMember/delete', this.staffMember.id);
    },
    /**
     * Check if this a new record
     */
    isNewRecord() {
      return this.$route.params.id == 'new' ? true : false;
    },
    /**
     * Reset the validation, this will make sure the dirty flags get set to
     * false after save action.
     */
    resetValidation() {
      requestAnimationFrame(() => {
        this.$refs.objects.reset();
      });
    },
    /**
     * Check if the form has changed after loading
     */
    isDirty() {
      return this.$refs.objects._data.flags.dirty;
    }
  }
};
</script>
