<template>
  <v-autocomplete
    v-model="address"
    :loading="loading"
    :items="items"
    :search-input.sync="search"
    no-data-text="Suburb not Found"
    item-text="location"
    item-value="id"
    auto-select-first
    clearable
    cache-items
    flat
    hide-no-data
    hide-details
    return-object
    label="Suburb"
    :disabled="disabled"
    @change="changeHandler"
  ></v-autocomplete>
</template>
<script>
import { getError } from '@/utils/helpers';
import SuburbService from '@/services/SuburbService';

export default {
  name: 'SuburbAutoComplete',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: function () {
        return {
          id: null,
          title: '',
          postcode: '',
          state: '',
          location: ''
        };
      }
    }
  },
  data() {
    return {
      loading: false,
      search: null,
      timerID: null,
      searchTerm: null,
      activeSuburb: null,
      items: [],
      error: null,
      init: false,
      location: null
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue, oldValue) {
        this.items = [];
        this.address = null;
        if (newValue && newValue != oldValue) {
          this.items.push(newValue);
          this.address = newValue;
        }
      }
    },
    search(value) {
      if (!value) return false;
      if (this.address && this.address.location === value) return false;
      this.searchTerm = value;
      this.fetchEntriesDebounced();
    }
  },
  methods: {
    changeHandler: function () {
      this.$emit('input', this.address);
    },
    fetchEntriesDebounced() {
      clearTimeout(this.timerID);
      this.timerID = setTimeout(() => {
        this.getSuburbsByNameOrPostCode(this.searchTerm);
      }, 1000);
    },
    getSuburbsByNameOrPostCode(name) {
      this.loading = true;
      SuburbService.getSuburbsByNameOrPostCode(name)
        .then(response => {
          this.items = response.data.data;
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          this.error = getError(error);
        });
    }
  }
};
</script>
