<template>
  <div>
    <v-text-field
      v-model="address.street"
      :disabled="disabled"
      :label="label"
      @input="updateHandler"
      class="address-field"
    ></v-text-field>
    <SuburbAutoComplete v-model="address.suburb" :disabled="disabled" @input="updateHandler" />
  </div>
</template>

<style scoped>
.address-field {
  margin-bottom: 30px;
}
</style>

<script>
import SuburbAutoComplete from '@/components/SuburbAutoComplete';

export default {
  name: 'AddressField',
  components: {
    SuburbAutoComplete
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object
    },
    label: {
      type: String,
      default: ''
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue, oldValue) {
        this.address = { street: '', suburb: null };
        if (newValue && newValue != oldValue) {
          this.address = { ...newValue };
        }
      }
    }
  },
  methods: {
    updateHandler: function () {
      this.$emit('input', this.address);
    }
  },
  data() {
    return {
      init: false,
      address: { street: '', suburb: null }
    };
  }
};
</script>
