<template>
  <v-container>
    <v-breadcrumbs :items="breadcrumbs" divider=">"></v-breadcrumbs>
    <h1 class="headline pb-4 mx-lg-auto">{{ title }}</h1>
    <v-tabs v-model="tabs" background-color="transparent">
      <v-tab>Details</v-tab>
    </v-tabs>
    <v-card>
      <v-card-text>
        <v-tabs-items v-model="tabs">
          <v-tab-item transition="false">
            <v-card flat>
              <v-card-text>
                <StaffMemberDetailForm ref="staffMemberDetailForm" />
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
    <ConfirmDialog :show="showNavigateAwayConfirmDialog" @confirm-dialog-clicked="confirmNavigate" />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import store from '@/store/index';
import StaffMemberDetailForm from '@/views/StaffMemberDetailForm';
import ConfirmDialog from '@/views/dialogs/ConfirmDialog';

export default {
  name: 'StaffMemberView',
  beforeRouteLeave(to, from, next) {
    if (this.$refs.staffMemberDetailForm.isDirty()) {
      this.next = next;
      this.showNavigateAwayConfirmDialog = true;
    } else {
      next();
    }
  },
  beforeRouteEnter(to, from, next) {
    store.dispatch('staffMember/get', to.params.id).then(() => {
      next();
    });
  },
  components: {
    StaffMemberDetailForm,
    ConfirmDialog
  },
  computed: {
    ...mapGetters('staffMember', ['loading', 'error', 'staffMember', 'meta']),
    title: function () {
      if (this.staffMember && this.staffMember.first_name) {
        return this.staffMember.first_name + ' ' + this.staffMember.surname;
      }
      return 'New Staff Member';
    }
  },
  methods: {
    confirmNavigate: function (navigateAway) {
      this.showNavigateAwayConfirmDialog = false;
      if (navigateAway) {
        this.next();
      }
    }
  },
  data: () => ({
    tabs: null,
    showNavigateAwayConfirmDialog: false,
    breadcrumbs: [
      {
        text: 'Dashboard',
        exact: true,
        to: '/dashboard'
      },
      {
        text: 'Staff Members',
        exact: true,
        to: '/staff-members'
      },
      {
        text: 'Staff Member',
        disabled: true,
        exact: true,
        to: '/staff-members/:id'
      }
    ]
  })
};
</script>
